import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from 'environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { NgMapsCoreModule } from '@ng-maps/core';
import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from '@ng-maps/google';
import firebase from 'firebase/compat/app';

firebase.initializeApp(environment.firebase);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase, 'Trapim'),
    AngularFireAuthModule,
    HttpClientModule,
    NgMapsCoreModule,
    NgMapsGoogleModule,

    // NgMapsGoogleModule.forRoot({
    //   apiKey: environment.gmapApiKey,
    //   libraries: ['places'],
    // }),
  ],
  providers: [
    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useValue: {
        apiKey: environment.gmapApiKey,
        libraries: ['places'],
      },
    },
  ],
})
export class CoreModule {}
