<h3>{{ data.title }}</h3>
<p>{{ data.text }}</p>

<div
  *ngIf="!data.prompt"
  mat-dialog-actions
  fxLayoutAlign="center center"
  style="margin-bottom: 10px"
>
  <button mat-button [mat-dialog-close]="true">Ya</button>
  <button mat-button mat-dialog-close>Tidak</button>
</div>

<div *ngIf="data.prompt">
  <mat-form-field>
    <input
      matInput
      #prompt
      [type]="data.promptType === 'password' ? 'password' : 'text'"
    />
  </mat-form-field>

  <div mat-dialog-actions fxLayoutAlign="center center">
    <button mat-button [mat-dialog-close]="prompt.value">Submit</button>
  </div>
</div>
