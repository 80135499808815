<div id="navigation-wrapper" fxLayout="row">
  <!-- Mini Side Nav for small devices -->
  <section
    #miniSidenav
    class="miniSidenav"
    fxFlex="70px"
    *ngIf="media.isActive('gt-xs')"
    [ngClass]="{ open: showMiniSidenav, close: !showMiniSidenav }"
  >
    <div class="brand">
      <h2>T</h2>
    </div>

    <div
      class="sidenav-wrap"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      position="end"
    >
      <div *ngFor="let menu of menus">
        <mat-icon
          fxFlexFill
          [matTooltip]="menu.title"
          matTooltipPosition="right"
          [routerLink]="menu.path"
          routerLinkActive="active-menu"
          >{{ menu.icon }}</mat-icon
        >
      </div>

      <div class="logout">
        <mat-icon fxFlexFill matTooltip="Logout" matTooltipPosition="right"
          >exit_to_app</mat-icon
        >
      </div>
    </div>
  </section>

  <!-- Large Side Nav for large screens -->
  <section
    fxFlex
    class="sideNavContainer"
    [ngClass]="{ showMargin: showMiniSidenav }"
    #sidenavContainer
  >
    <mat-sidenav-container>
      <mat-sidenav
        #sidenav
        [mode]="(isHandset$ | async) ? 'push' : 'side'"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [fixedInViewport]="true"
        [(opened)]="showSidenav"
      >
        <div
          class="sidenav-wrap"
          fxFlexFill
          fxLayout="column"
          fxLayoutWrap
          fxLayoutAlign="start"
        >
          <div class="brand">
            <h2>Trapim</h2>
          </div>

          <div
            class="mid-section"
            fxLayout="column"
            fxLayoutAlign="start start"
          >
            <div *ngFor="let menu of menus">
              <a
                [routerLink]="menu.path"
                routerLinkActive="active-menu"
                style="outline: none !important"
              >
                <mat-icon>{{ menu.icon }}</mat-icon>
                <span>{{ menu.title }}</span>
              </a>
            </div>
            <!-- <div>
              <a (click)="onLogout()">
                <mat-icon>exit_to_app</mat-icon>
                Sign out
              </a>
            </div> -->
          </div>

          <div
            fxFlex="10"
            fxLayout="row"
            fxLayoutAlign="start end"
            class="bottom-section"
          >
            <span class="bottom-section-copyright">
              Copyright &copy; {{ year }}. Version {{ version }}
            </span>
          </div>
        </div>
      </mat-sidenav>

      <mat-sidenav-content>
        <!-- Navigation toolbar -->
        <mat-toolbar class="navigation-toolbar">
          <div fxFlex fxLayoutAlign="start center">
            <mat-icon
              style="font-size: 1.1em !important"
              (click)="onToggleMenu()"
              >menu</mat-icon
            >
            <h1 style="margin-left: 10px">{{ title || "dashboard" }}</h1>
          </div>

          <div fxFlex="30" class="profile" fxLayoutAlign="end center">
            <div
              [matMenuTriggerFor]="menu"
              fxLayout="row"
              fxLayoutAlign="end center"
            >
              <span fxFlex style="font-size: 0.8em"
                >Hi, {{ membername || "Admin" }}</span
              >
              <mat-icon fxFlex class="profile-arrow">chevron_right</mat-icon>
            </div>

            <mat-menu #menu="matMenu" [overlapTrigger]="false">
              <span mat-menu-item (click)="profile.emit()">Profile</span>
              <span mat-menu-item (click)="password.emit()">Ubah Password</span>
              <span mat-menu-item (click)="onLogout()">Sign out</span>
            </mat-menu>

            <div
              class="profile-image"
              fxLayoutAlign="start center"
              *ngIf="media.isActive('gt-xs')"
            >
              <img
                (click)="profile.emit()"
                [src]="memberimage || 'assets/default_profile.png'"
              />
            </div>
          </div>
        </mat-toolbar>

        <div class="content">
          <!-- Content here -->
          <ng-content></ng-content>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </section>
</div>
