import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { UploadMediaRes } from './media.model';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor() {}

  uploadMedia(file: File): Observable<UploadMediaRes> {
    const formData = new FormData();
    formData.append('upload_preset', 'public_upload');
    formData.append('file', file);

    return from(
      fetch(`${environment.cloudinaryURL}/image/upload`, {
        method: 'POST',
        body: formData,
      }).then((res) => res.json())
    );
  }
}
