import { Component, OnInit } from '@angular/core';
import { TourService } from '@core/tour/tour.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  tourCount = 0;

  constructor(private tourService: TourService) {}

  ngOnInit() {
    this.tourService.listTours({}).subscribe((res) => {
      this.tourCount = res?.meta?.total || 0;
    });
  }
}
