<div class="overlay" [class.active]="loading" *ngIf="!disabled">
  <div class="actions">
    <mat-spinner
      *ngIf="loading; else actionBlock"
      [diameter]="25"
      [strokeWidth]="4"
      color="primary"
    ></mat-spinner>

    <ng-template #actionBlock>
      <button
        mat-icon-button
        type="button"
        color="primary"
        *ngIf="delete.observed"
        (click)="delete.emit()"
      >
        <mat-icon class="action-btn">delete</mat-icon>
      </button>

      <button
        mat-icon-button
        type="button"
        class="ml-5"
        color="primary"
        *ngIf="edit.observed"
        (click)="edit.emit()"
      >
        <mat-icon class="action-btn">edit</mat-icon>
      </button>
    </ng-template>
  </div>
</div>
