<div class="form-container" [formGroup]="form">
  <!-- <mat-form-field>
    <mat-label>Select Currency</mat-label>
    <mat-select formControlName="curr">
      <mat-option *ngFor="let item of currency" [value]="item">
        {{ item }}
      </mat-option>
    </mat-select>
  </mat-form-field> -->

  <mat-form-field>
    <input matInput placeholder="Half Day" formControlName="half" />
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Full Day" formControlName="full" />
  </mat-form-field>

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Batal</button>
    <button mat-button (click)="onDone()" color="primary" cdkFocusInitial>
      Selesai
    </button>
  </div>
</div>
