import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminGuard } from '@core/auth/admin.guard';
import { AdminComponent } from './admin.component';
import { ForbiddenComponent } from './static/forbidden/forbidden.component';

const routes: Routes = [
  { path: '', redirectTo: 'admin/dashboard', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'forbidden', component: ForbiddenComponent },
      {
        path: 'profile',
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
        data: { title: 'Profile' },
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./users/users.module').then((m) => m.UsersModule),
        data: { title: 'Users' },
      },
      {
        path: 'roles',
        loadChildren: () =>
          import('./roles/roles.module').then((m) => m.RolesModule),
        data: { title: 'Roles' },
      },
      {
        path: 'posts',
        loadChildren: () =>
          import('./posts/posts.module').then((m) => m.PostsModule),
        data: { title: 'Posts' },
      },
      {
        path: 'tours',
        loadChildren: () =>
          import('./tours/tours.module').then((m) => m.ToursModule),
        data: { title: 'Tours' },
      },
      {
        path: 'itineraries',
        loadChildren: () =>
          import('./itineraries/itineraries.module').then(
            (m) => m.ItinerariesModule
          ),
        data: { title: 'Itineraries' },
      },
      {
        path: 'photos',
        loadChildren: () =>
          import('./photos/photos.module').then((m) => m.PhotosModule),
        data: { title: 'Photos' },
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./orders/orders.module').then((m) => m.OrdersModule),
        data: { title: 'Orders' },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
