<mat-form-field>
  <input
    type="text"
    [placeholder]="placeholder"
    aria-label="Number"
    matInput
    [formControl]="hourControl"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngFor="let option of filteredHours | async"
      [value]="option"
      (onSelectionChange)="onSelectionChange($event)"
    >
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
