<div>
  <h3 class="h3" mat-dialog-title>{{ data.title }}</h3>

  <mat-dialog-content>
    <span class="body-2" *ngIf="data.text">
      {{ data.text }}
    </span>
    <span class="body-2" *ngIf="data.html" [innerHTML]="data.html"> </span>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="mt-10" style="padding-bottom: 20px">
    <button mat-raised-button mat-dialog-close type="button" color="primary">
      OK
    </button>
  </mat-dialog-actions>
</div>
