import {
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  Component,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MapsAPILoader } from '@ng-maps/core';
import { MapService } from './map.service';

declare var google: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [],
})
export class MapComponent implements OnInit {
  public latitude: number;
  public longitude: number;
  public searchControl: FormControl;
  public zoom: number;
  private loadingInfo: boolean;
  private initialized: boolean;

  @ViewChild('search') public searchElementRef: ElementRef;
  @ViewChild('map') public map: any;

  @Output() locationChange = new EventEmitter();
  @Input()
  set initial({ latitude, longitude }) {
    if (latitude && longitude) {
      setTimeout(() => {
        this.updateLocation(latitude, longitude);
        this.zoom = 16;
      }, 1000);
    }
  }

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private mapService: MapService
  ) {}

  ngOnInit() {
    // set google maps defaults
    this.zoom = 11;
    // this.latitude = -6.212097;
    // this.longitude = 106.8272143;

    // create search FormControl
    this.searchControl = new FormControl();

    // set current position
    this.setCurrentPosition();

    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.setComponentRestrictions({ country: ['id'] });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.updateLocation(
            place.geometry.location.lat(),
            place.geometry.location.lng()
          );

          this.zoom = 16;

          if (place.formatted_address) {
            this.searchControl.setValue(
              place.name + ' - ' + place.formatted_address
            );
            // const address = [
            //   (place.address_components[0] && place.address_components[0].short_name) || '',
            //   (place.address_components[1] && place.address_components[1].short_name) || '',
            //   (place.address_components[2] && place.address_components[2].short_name) || '',
            // ].join(' ');
          }
        });
      });
    });
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.updateLocation(
          position.coords.latitude,
          position.coords.longitude
        );

        this.zoom = 16;
      });
    }
  }

  // onCenterChange(event: LatLngLiteral) {
  //   console.log(event)
  //   this.latitude = event.lat;
  //   this.longitude = event.lng;
  // }

  onMapReady() {
    setTimeout(() => {
      this.initialized = true;
    }, 1000);
  }

  onIdle() {
    // if (this.initialized && !this.loadingInfo) {
    //   if (
    //     this.latitude === this.map.latitude &&
    //     this.longitude === this.map.longitude
    //   ) {
    //     return;
    //   }
    //   this.loadingInfo = true;
    //   this.searchControl.setValue('');
    // }
    // this.updateLocation(this.map.latitude, this.map.longitude);
  }

  updateLocation(latitude, longitude) {
    this.latitude = latitude;
    this.longitude = longitude;

    this.locationChange.emit({
      latitude,
      longitude,
    });
  }

  onMarkerDragEnd(e: google.maps.MouseEvent) {
    const data = e.latLng.toJSON();

    this.updateLocation(data.lat, data.lng);

    this.mapService
      .getLocationName({
        lat: data.lat,
        lng: data.lng,
      })
      .then((res) => {
        this.searchControl.setValue(res);
        this.loadingInfo = false;
      })
      .catch((err) => {
        console.log('err', err);
        this.loadingInfo = false;
      });
  }
}
