import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { map, filter, mergeMap } from 'rxjs/operators';
import pkg from '../../../package.json';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @Input() menus: { title: string; path: string; icon: string }[];
  @Output() logout = new EventEmitter();
  @Output() password = new EventEmitter();
  @Output() profile = new EventEmitter();
  @Input() membername: string;
  @Input() memberimage: string;

  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('sidenavContainer') sidenavContainer: ElementRef;
  @ViewChild('miniSidenav') miniSidenav: MatSidenav;

  private mediaSubscription: Subscription;
  public showSidenav = false;
  public showMiniSidenav = false;
  public clickedClose = false;
  public title = '';
  public year = new Date().getFullYear();
  public version = pkg.version;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    public media: MediaObserver,
    private changeRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    /* Check if gt-sm we open the sidenav, close is the default */
    if (this.media.isActive('gt-sm')) {
      this.showSidenav = true;
    }

    /* Getting the title from user routing */
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
            return route;
          }
          return route;
        }),
        filter((route) => route?.outlet === 'primary'),
        mergeMap((route) => {
          return route ? route.data : of(null);
        })
      )
      .subscribe((data) => {
        this.title = data?.['title'];
        if (this.sidenav.opened && this.media.isActive('lt-sm')) {
          this.sidenav.close();
        }
      });

    this.mediaSubscription = this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        if (result.breakpoints[Breakpoints.Small]) {
          this.showSidenav = false;
          this.showMiniSidenav = true;
        } else if (result.breakpoints[Breakpoints.XSmall]) {
          this.showSidenav = false;

          if (this.sidenavContainer?.nativeElement) {
            this.renderer.removeClass(
              this.sidenavContainer.nativeElement,
              'showMargin'
            );
          }
          this.showMiniSidenav = false;
        } else {
          if (this.clickedClose === false) {
            this.showSidenav = true;
            this.showMiniSidenav = false;
          }
        }
      });
  }

  ngAfterViewChecked() {
    this.changeRef.detectChanges();
  }

  onToggleMenu() {
    this.sidenav.toggle();
    this.showMiniSidenav = this.sidenav.opened ? false : true;
    this.clickedClose = this.sidenav.opened ? false : true;
  }

  // sidenavCloseCallback() {
  //   if (this.media.isActive('gt-xs')) {
  //     this.renderer.removeClass(this.miniSidenav.nativeElement, 'close');
  //     this.renderer.addClass(this.miniSidenav.nativeElement, 'open');
  //     this.showMiniSidenav = this.sidenav.opened ? false : true;
  //   }
  // }

  // sidenavOpenCallback() {
  //   if (this.media.isActive('gt-xs')) {
  //     this.renderer.removeClass(this.miniSidenav.nativeElement, 'open');
  //     this.renderer.addClass(this.miniSidenav.nativeElement, 'close');
  //   }
  // }

  ngOnDestroy() {
    this.mediaSubscription.unsubscribe();
  }

  onMiniMenuClick() {
    this.miniSidenav.close();
  }

  onLogout() {
    this.logout.emit();
  }
}
