import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  MatDateRangeSelectionStrategy,
  DateRange,
} from '@angular/material/datepicker';

@Injectable()
export class DayRangeSelectionStrategy<D>
  implements MatDateRangeSelectionStrategy<D>
{
  public dayRange: number;

  constructor(
    // @Inject('dayRange') private dayRange: number,
    private _dateAdapter: DateAdapter<D>
  ) {}

  selectionFinished(date: D | null): DateRange<D> {
    return this._createDayRange(date);
  }

  createPreview(activeDate: D | null): DateRange<D> {
    return this._createDayRange(activeDate);
  }

  private _createDayRange(date: D | null): DateRange<D> {
    if (date) {
      // const start = this._dateAdapter.addCalendarDays(date, -2);
      const end = this._dateAdapter.addCalendarDays(date, this.dayRange);
      return new DateRange<D>(date, end);
    }

    return new DateRange<D>(null, null);
  }
}
