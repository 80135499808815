import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-guide-price',
  templateUrl: './guide-price.component.html',
  styleUrls: ['./guide-price.component.scss']
})
export class GuidePriceComponent implements OnInit {
  public form: FormGroup;
  public currency = [
    'USD',
    'EUR',
    'JPY',
    'GBP',
    'AUD',
    'CAD',
    'CNY',
    'MXN',
    'IDR',
    'SGD'
  ];

  constructor(
    private fb: FormBuilder, 
    public dialogRef: MatDialogRef<GuidePriceComponent>
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      curr: [''],
      half: [''],
      full: ['']
    })
  }

  onDone(){
    if(this.form.valid){
      this.dialogRef.close(this.form.getRawValue());
    }
  }

}
