export enum Permission {
  // Post
  PostCreate = 'post:Create',
  PostUpdate = 'post:Update',
  PostDelete = 'post:Delete',
  PostRead = 'post:Read',
  // Role
  RoleWrite = 'roles:write',
  RoleRead = 'roles:read',
  // User
  UserWrite = 'users:write',
  UserRead = 'users:read',
  // Tour
  TourRead = 'agent/tours:read',
  TourWrite = 'agent/tours:write',
  // Order
  OrderRead = 'agent/orders:read',
  OrderWrite = 'agent/orders:write',
}
