<div mat-dialog-title>
  <section fxLayout="row" fxLayoutWrap fxLayoutGap="10px">
    <mat-form-field
      fxFlex="auto"
      floatLabel="never"
      class="form-field-infix-auto"
    >
      <input
        matInput
        #filter
        placeholder="Cari foto dengan nama"
        autocomplete="off"
      />
    </mat-form-field>
    <span fxFlex="initial">
      <input
        type="file"
        #fileInput
        class="input-file"
        accept="image/*"
        (change)="onFileChange($event)"
      />

      <button
        mat-raised-button
        color="primary"
        class="button"
        (click)="fileInput.click()"
      >
        Upload baru
      </button>
    </span>
  </section>
</div>
<mat-dialog-content>
  <div class="container" fxFlexLayout="column">
    <mat-progress-bar
      *ngIf="dataSource?.loading$ | async"
      mode="indeterminate"
      class="spinner"
    ></mat-progress-bar>

    <div class="photo-grid">
      <div class="breadcrumb">
        <div>
          <span class="text-link" (click)="onFileAndFoldersClick()"
            >Files & Folders</span
          >
          <span
            *ngFor="let p of dataSource.breadcrumbs; let i = index"
            style="display: flex; align-items: center"
            class="text-link"
            [ngClass]="{ 'text-link': i !== dataSource.breadcrumbs.length - 1 }"
            (click)="onFolderClick(p)"
          >
            <mat-icon>keyboard_arrow_right</mat-icon>
            {{ p.fileName }}
          </span>
        </div>

        <div>
          <!-- <mat-icon class="new">add_box</mat-icon>
          <span (click)="onAddFolder()" class="text-link">Tambah Folder</span> -->
        </div>
      </div>

      <mat-grid-list
        [cols]="gridCols"
        class="photos"
        rowHeight="1:1"
        gutterSize="23"
      >
        <mat-grid-tile
          *ngFor="let item of dataSource.itemsSubject | async; let i = index"
          [ngClass]="{ folder: item.type === 'folder' }"
        >
          <div *ngIf="item.type === 'folder'">
            <mat-icon
              matRipple
              class="folder-icon"
              (click)="onFolderClick(item)"
              [matRippleUnbounded]="true"
              >folder</mat-icon
            >
            <span class="caption-2">{{ item.fileName }}</span>
          </div>
          <div
            *ngIf="item.type !== 'folder'"
            fxLayoutAlign="center center"
            fxLayout="column"
            class="photo-item"
          >
            <div style="position: relative">
              <img class="img-preview" [src]="item.previewURL" />

              <div class="marker" [class.selected]="item.selected">
                <mat-icon class="check-icon">check</mat-icon>
              </div>

              <app-overlay-action
                [loading]="!!item.loading"
                (click)="onSelect(item)"
              ></app-overlay-action>
            </div>
            <span class="caption-2">{{ item.fileName }}</span>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <mat-card>
      <div fxLayout="row" fxLayoutAlign="end end">
        <button
          mat-icon-button
          [disabled]="!dataSource.hasPrev()"
          (click)="onPrev()"
        >
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button
          mat-icon-button
          [disabled]="!dataSource.hasNext()"
          (click)="onNext()"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </mat-card>
  </div>

  <!-- <mat-tab-group style="height: 100%" animationDuration="0ms">
    <mat-tab label="Choose Image">
      <mat-grid-list
        cols="4"
        rowHeight="4:3"
        class="photos mt-20"
        gutterSize="4"
      >
        <mat-grid-tile *ngFor="let item of files">
          <div
            fxLayoutAlign="center center"
            fxLayout="column"
            class="photo-item"
            [class.selected]="item.selected"
          >
            <div>
              <img class="img-preview" [src]="item.s3URL" />
            </div>
          </div>

          <div class="overlay" (click)="onSelect(item)"></div>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-tab>
    <mat-tab label="Upload Image">
      <div class="upload-container" fxLayoutAlign="center center">
        <div fxFlex="15" fxFlex.xs="50">
          <div
            class="plus-button"
            fxLayoutAlign="center center"
            (click)="fileInput.click()"
          >
            <input
              type="file"
              #fileInput
              class="input-file"
              accept="image/*"
              (change)="onFileChange($event)"
            />

            <mat-icon class="plus-icon">upload</mat-icon>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Batal</button>
  <button
    mat-button
    color="primary"
    (click)="onSave()"
    [disabled]="!selectedFiles.length"
  >
    {{
      "Gunakan foto " +
        (selectedFiles.length ? "(" + selectedFiles.length + ")" : "")
    }}
  </button>
</mat-dialog-actions>
