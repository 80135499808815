<div>
  <div
    class="container"
    fxLayout="row"
    fxLayoutGap="15px"
    fxLayout.xs="column wrap"
  >
    <mat-card>
      <h1>{{ tourCount }}</h1>
      <span> Total Tours </span>
    </mat-card>
    <mat-card>
      <h1>0</h1>
      <span> Pending Orders </span>
    </mat-card>
    <mat-card>
      <h1>0</h1>
      <span> Confirmed Orders </span>
    </mat-card>
    <mat-card>
      <h1>0</h1>
      <span> Completed Orders </span>
    </mat-card>
  </div>
  <!-- <div
    class="container"
    fxLayout="row"
    fxLayoutGap="15px"
    fxLayout.xs="column wrap"
  >
    <mat-card>
      <app-charts></app-charts>
    </mat-card>
  </div> -->
</div>
