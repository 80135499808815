<div class="checkboxes" fxLayout="row" fxLayoutGap="20px">
  <mat-checkbox color="primary" [(ngModel)]="dateMode">
    Use Date mode
  </mat-checkbox>

  <mat-checkbox
    color="primary"
    [(ngModel)]="fullDay"
    (change)="onFullDayChange($event)"
  >
    24 Hours
  </mat-checkbox>
</div>

<div class="form-container" [formGroup]="form">
  <mat-form-field *ngIf="!dateMode">
    <mat-label>Select Day</mat-label>
    <mat-select formControlName="day">
      <mat-option *ngFor="let item of days" [value]="item">
        {{ item }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="dateMode">
    <input
      matInput
      [matDatepicker]="picker"
      placeholder="Choose a date"
      formControlName="date"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <app-time-picker
    placeholder="Start hour"
    formControlName="startTime"
  ></app-time-picker>

  <app-time-picker
    placeholder="End hour"
    formControlName="endTime"
  ></app-time-picker>

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Batal</button>
    <button mat-button (click)="onDone()" color="primary" cdkFocusInitial>
      Selesai
    </button>
  </div>
</div>
