import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  forwardRef,
  OnDestroy,
  Input,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ControlContainer,
  FormGroupDirective,
} from '@angular/forms';
import { MediaService } from '@core/media/media.service';
import { lastValueFrom } from 'rxjs';
import { RawEditorOptions } from 'tinymce';

// declare var window: any;
// window.tinyMCE.overrideDefaults({
//   base_url: '/tinymce/', // Base for assets such as skins, themes and plugins
//   suffix: '.min', // This will make Tiny load minified versions of all its assets
// });

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class EditorComponent implements OnInit, OnDestroy {
  @Input() controlName: string;

  public tinyMceInit: RawEditorOptions = {
    base_url: '/tinymce', // Root for resources
    suffix: '.min', // Suffix to use when loading resources
    branding: false,
    menubar: false,
    plugins:
      'advlist autolink lists link image charmap preview anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking save table directionality emoticons template',
    toolbar: `formatselect | bold italic strikethrough |
      alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | fullscreen`,
    height: '380px',
    image_title: true,
    image_advtab: true,
    images_upload_url: 'noop', // without images_upload_url set, Upload tab won't show up
    images_upload_hander: async (blobInfo, progress) => {
      const upload = await lastValueFrom(
        this.mediaService.uploadMedia(blobInfo.blob())
      );
      return upload?.secure_url;
    },
  };

  // private _value;

  constructor(private mediaService: MediaService) {}

  ngOnInit() {}

  // _onChange: any = () => {};
  // _onTouched: any = () => {};

  // get value() {
  //   return this._value;
  // }

  // set value(val) {
  //   this._value = val;
  //   this._onChange(val);
  // }

  // writeValue(value) {
  //   if (value) {
  //     this.value = value;
  //   }
  // }

  // onChange(event) {
  //   console.log(event);
  // }

  // registerOnChange(fn) {
  //   this._onChange = fn;
  // }

  // registerOnTouched(fn) {
  //   this._onTouched = fn;
  // }

  ngOnDestroy() {}
}
