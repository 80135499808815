// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  userApiURL: 'https://api.dev.trapim.com/users',
  tourApiURL: 'https://api.dev.trapim.com/tours',
  fileApiURL: 'https://api.dev.trapim.com/files',
  orderApiURL: 'https://api.dev.trapim.com/orders',
  // userApiURL: 'http://localhost:3001/users',
  // tourApiURL: 'http://localhost:3001/tours',
  // fileApiURL: 'http://localhost:3001/files',
  // orderApiURL: 'http://localhost:3001/orders',
  cloudinaryURL: 'https://api.cloudinary.com/v1_1/mongotrip',
  firebase: {
    apiKey: 'AIzaSyBwVDEBFy7N1jebT9lbby0jg25Xk4SkPWU',
    authDomain: 'trapim-dev.firebaseapp.com',
    projectId: 'trapim-dev',
    storageBucket: 'trapim-dev.appspot.com',
    messagingSenderId: '520130748338',
    appId: '1:520130748338:web:bbf3f2d4dd26e0b2a98eab',
  },
  gmapApiKey: 'AIzaSyDN7rKxq9bawGj246gtpCO1n_Px8PMpIvs',
  previewURL: 'https://dev.trapim.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
