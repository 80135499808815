import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS
} from '@angular/common/http';

import { AuthService } from './auth.service';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    return from(this.auth.getToken()).pipe(
      switchMap(authToken => {
        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        
        const authReq = req.clone({
          headers: req.headers.set('Authorization', authToken || '')
        });

        // send cloned request with header to the next handler.
        return next.handle(authReq);
      })
    );
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
