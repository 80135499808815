import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

export const removeEmptyValues = (obj) => {
  const propNames = Object.getOwnPropertyNames(obj);
  for (let i = 0; i < propNames.length; i++) {
    const propName = propNames[i];
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      delete obj[propName];
    }
  }

  return obj;
};

export const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const handleError = (error: HttpErrorResponse) => {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` + `body was: ${error.error}`
    );
  }
  // return an observable with a user-facing error message
  return throwError('Something bad happened; please try again later.');
};

export const removeExtension = (fileName: string) => {
  return fileName.split('.').slice(0, -1).join('.');
};

export const getSquareMediumImageUrl = (imageUrl: string) => {
  const parts = imageUrl.split('.');
  const ext = parts.pop();
  const urlWithoutExt = parts.join('.');
  return `${urlWithoutExt}-square-m.${ext}`;
};
