<div class="container" fxLayout="column">
  <mat-form-field floatLabel="never" class="search-input">
    <input
      matInput
      type="text"
      placeholder="Cari lokasi"
      autocorrect="off"
      spellcheck="off"
      type="text"
      #search
      [formControl]="searchControl"
    />
    <button
      mat-button
      color="primary"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="searchControl.setValue('')"
      type="button"
    >
      <mat-icon class="search-icon">close</mat-icon>
    </button>
  </mat-form-field>

  <div fxFlex>
    <div class="centerMarker"></div>

    <map-view
      #map
      [latitude]="latitude"
      [longitude]="longitude"
      [scrollwheel]="false"
      [disableDefaultUI]="true"
      (idle)="onIdle()"
      (mapReady)="onMapReady()"
      [zoom]="zoom"
    >
      <map-marker
        [latitude]="latitude"
        [longitude]="longitude"
        [markerDraggable]="true"
        (dragEnd)="onMarkerDragEnd($event)"
      ></map-marker>
    </map-view>
  </div>
</div>
