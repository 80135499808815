import { Injectable } from '@angular/core';
import { Tour } from './models/tour';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { ApiResponse } from '@core/api';
import { Package } from './models/package';
import { Itinerary } from './models/itinerary';
import { PatchTourRequest } from './models/patch-tour-request';

@Injectable({
  providedIn: 'root',
})
export class TourService {
  constructor(private http: HttpClient) {}

  listTours(p: {
    limit?: number;
    offset?: number;
    sortBy?: string;
    sortDirection?: string;
    search?: string;
  }) {
    const params = new HttpParams()
      .set('limit', `${p.limit}`)
      .set('offset', `${p.offset}`)
      .set('sortBy', `${p.sortBy}`)
      .set('sortDirection', `${p.sortDirection}`)
      .set('search', `${p.search || ''}`);

    return this.http
      .get<ApiResponse<Tour[]>>(environment.tourApiURL + '/manage/list', {
        params,
      })
      .pipe(map((res) => res));
  }

  getTour(id: string) {
    return this.http
      .get<ApiResponse<Tour>>(environment.tourApiURL + '/manage/' + id)
      .pipe(map((res) => res));
  }

  createDraft(data: PatchTourRequest) {
    return this.http
      .post<ApiResponse<Tour>>(environment.tourApiURL + '/manage/draft', data)
      .pipe(map((res) => res));
  }

  patchTour(id: string, data: PatchTourRequest) {
    return this.http
      .patch<ApiResponse<Tour>>(environment.tourApiURL + '/manage/' + id, data)
      .pipe(map((res) => res));
  }

  publishTour(id: string, data: PatchTourRequest) {
    return this.http
      .post<ApiResponse<Tour>>(
        environment.tourApiURL + '/manage/' + id + '/publish',
        data
      )
      .pipe(map((res) => res));
  }

  unpublishTour(id: string) {
    return this.http
      .post<ApiResponse<Tour>>(
        environment.tourApiURL + '/manage/' + id + '/unpublish',
        {}
      )
      .pipe(map((res) => res));
  }

  deleteTour(id: string) {
    return this.http
      .delete<ApiResponse<boolean>>(environment.tourApiURL + '/manage/' + id)
      .pipe(map((res) => res));
  }

  createTourPackage(tourId: string, data: Package) {
    return this.http
      .post<ApiResponse<Package>>(
        environment.tourApiURL + `/manage/${tourId}/packages`,
        data
      )
      .pipe(map((res) => res));
  }

  updateTourPackage(tourId: string, data: Package) {
    return this.http
      .put<ApiResponse<Package>>(
        environment.tourApiURL + `/manage/${tourId}/packages/${data.id}`,
        data
      )
      .pipe(map((res) => res));
  }

  deleteTourPackage(tourId: string, data: Package) {
    return this.http
      .delete<ApiResponse<Package>>(
        environment.tourApiURL + `/manage/${tourId}/packages/${data.id}`
      )
      .pipe(map((res) => res));
  }

  createTourItinerary(tourId: string, data: Itinerary) {
    return this.http
      .post<ApiResponse<Itinerary>>(
        environment.tourApiURL + `/manage/${tourId}/itineraries`,
        data
      )
      .pipe(map((res) => res));
  }

  updateTourItinerary(tourId: string, data: Itinerary) {
    return this.http
      .put<ApiResponse<Itinerary>>(
        environment.tourApiURL + `/manage/${tourId}/itineraries/${data.id}`,
        data
      )
      .pipe(map((res) => res));
  }

  deleteTourItinerary(tourId: string, data: Itinerary) {
    return this.http
      .delete<ApiResponse<boolean>>(
        environment.tourApiURL + `/manage/${tourId}/itineraries/${data.id}`
      )
      .pipe(map((res) => res));
  }

  createItinerary(data: Itinerary) {
    return this.http
      .post<ApiResponse<Itinerary>>(
        environment.tourApiURL + `/manage/itineraries`,
        data
      )
      .pipe(map((res) => res));
  }

  updateItinerary(data: Itinerary) {
    return this.http
      .put<ApiResponse<Itinerary>>(
        environment.tourApiURL + `/manage/itineraries/${data.id}`,
        data
      )
      .pipe(map((res) => res));
  }

  deleteItinerary(data: Itinerary) {
    return this.http
      .delete<ApiResponse<Itinerary>>(
        environment.tourApiURL + `/manage/itineraries/${data.id}`
      )
      .pipe(map((res) => res));
  }
}
