import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { startWith, map } from 'rxjs/operators';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
  ],
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string;

  hourControl = new FormControl();
  filteredHours: Observable<string[]>;
  hours: string[] = [
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 AM',
    '12:30 AM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
    '12:00 PM',
    '12:30 PM',
  ];

  constructor() {
    this.filteredHours = this.hourControl.valueChanges.pipe(
      startWith(''),
      map(t => (t ? this._filterHours(t) : this.hours.slice()))
    );
  }

  @Input()
  get value() {
    return this.hourControl.value;
  }

  set value(val) {
    this.hourControl.setValue(val);
    this.onChange(val);
    this.onTouched();
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.hourControl.disable();
    } else {
      this.hourControl.enable();
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value) {
      this.hourControl.setValue(value);
    }
  }

  onSelectionChange(event) {
    this.onChange(event.source.value);
  }

  ngOnInit() {}

  private _filterHours(value: string): string[] {
    const filterValue = value.toLowerCase();
    const times = filterValue.split(':');
    const mins = times[1] || '';

    if (mins.length === 2) {
      const minutes = mins.padStart(2, '0');
      return [`${times[0]}:${minutes} AM`, `${times[0]}:${minutes} PM`];
    } else if (mins.length === 1) {
      const hoursAm = Array(10)
        .fill(0)
        .map((v, i) => `${times[0]}:${mins}${i} AM`);
      const hoursPm = Array(10)
        .fill(0)
        .map((v, i) => `${times[0]}:${mins}${i} PM`);
      return [...hoursAm, ...hoursPm];
    }

    return this.hours.filter(hour => hour.toLowerCase().indexOf(filterValue) === 0);
  }
}
