import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MapsAPILoader } from '@ng-maps/core';
import { GoogleMapsAPIWrapper } from '@ng-maps/google';

declare var google: any;

@Injectable({
  providedIn: 'root',
})
export class MapService extends GoogleMapsAPIWrapper {
  constructor(
    private __loader: MapsAPILoader,
    private __zone: NgZone,
    private http: HttpClient
  ) {
    super(__loader, __zone);
  }

  getLocationName(event: any): Promise<string> {
    const geocoder = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode(
        { location: { lat: event.lat, lng: event.lng } },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve(results[0].formatted_address);
          } else {
            console.log('Error - ', results, ' & Status - ', status);
            resolve('');
          }
        }
      );
    });
  }
}
