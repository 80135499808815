import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss'],
})
export class OpeningHoursComponent implements OnInit {
  public dateMode: boolean;
  public fullDay: boolean;
  public days = [
    'Every day',
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<OpeningHoursComponent>
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      day: [''],
      date: [''],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
    });
  }

  onFullDayChange(event) {
    if (event.checked) {
      this.form.controls['startTime'].setValue('12:00 AM');
      this.form.controls['endTime'].setValue('11:59 PM');

      this.form.controls['startTime'].disable();
      this.form.controls['endTime'].disable();
    } else {
      this.form.controls['startTime'].enable();
      this.form.controls['endTime'].enable();
    }
  }

  onDone() {
    if (this.form.valid) {
      // we use form.getRawValue because disabled controls will lose its value in form.value
      const value = this.form.getRawValue();
      const openHours: any[] = [];

      if (value.day === 'Every day') {
        for (const [i, day] of this.days.slice(1).entries()) {
          openHours.push({
            id: Date.now() + i,
            day: day,
            date: value.date,
            startTime: value.startTime,
            endTime: value.endTime,
          });
        }
      } else {
        openHours.push({
          id: Date.now(),
          day: value.day,
          date: value.date,
          startTime: value.startTime,
          endTime: value.endTime,
        });
      }
      this.dialogRef.close(openHours);
    }
  }
}
