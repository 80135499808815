import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  isloading$ = new BehaviorSubject(false);

  start() {
    setTimeout(() => {
      this.isloading$.next(true);
    }, 0);
  }

  stop() {
    this.isloading$.next(false);
  }
}
