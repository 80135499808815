import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { FileResponse } from './file.model';
import { from, map, Observable } from 'rxjs';
import { ApiResponse } from '@core/api';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient) {}

  listFiles(p: {
    limit?: number;
    nextToken?: string;
    search?: string;
    parentId?: string;
  }) {
    const params = new HttpParams()
      .set('limit', `${p.limit || ''}`)
      .set('nextToken', `${p.nextToken || ''}`)
      .set('search', `${p.search || ''}`)
      .set('parentId', `${p.parentId || ''}`);

    return this.http
      .get<ApiResponse<FileResponse[]>>(environment.fileApiURL + '/list', {
        params,
      })
      .pipe(map((res) => res));
  }

  uploadFile(file: File, parentId?: string, skipCatalog?: boolean) {
    const formData = new FormData();
    formData.append('file', file);

    if (skipCatalog) {
      formData.append('skipCatalog', 'true');
    }

    if (parentId) {
      formData.append('parentId', parentId);
    }

    return this.http
      .post<ApiResponse<FileResponse>>(
        environment.fileApiURL + '/upload',
        formData
      )
      .pipe(map((res) => res));
  }

  renameFile(id: string, fileName: string) {
    return this.http
      .put<ApiResponse<FileResponse>>(environment.fileApiURL + `/${id}`, {
        fileName,
      })
      .pipe(map((res) => res));
  }

  createFolder(folderName: string, parentId?: string) {
    return this.http
      .post<ApiResponse<FileResponse>>(environment.fileApiURL + `/folder`, {
        folderName,
        parentId,
      })
      .pipe(map((res) => res));
  }

  deleteFile(id: string) {
    return this.http
      .delete<ApiResponse<boolean>>(environment.fileApiURL + `/${id}`)
      .pipe(map((res) => res));
  }
}
